import * as React from 'react';
import Container404 from '../components/Container404';
import Layout from '../components/Layout';

const NotFoundPage = () => (
    <Layout>
        <Container404 />
    </Layout>
);

export default NotFoundPage;
