import styled from '@emotion/styled';
import colors from '../../styles/colors/colors';

const ContainerBanner = styled.div`
    height: 100vh;
    @media only screen and (max-width: 980px) {
     height: auto;
    }
`;
const ContainerHeight = styled.div`
    height: auto;
    padding-top: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .logo__404 {
        margin-top: 91px;
    }

    @media only screen and (max-width: 460px) {
    padding-left: 10px;
    padding-right: 10px;
        .logo__Tupaca {
            width: 50px !important;
            height: 76px !important;
        }
        .logo__404 {
            width: 250px !important;
            height: 86px !important;
            margin-top: 40px;
        }
    }
    a {
        margin-top: 30px;
        float: right;
        border: 1px solid ${colors.primary};
        box-sizing: border-box;
        background-color: ${colors.white};
        width: 150px;
        height: 37px;
        font-family: Gotham-Book, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 35px;
        color: ${colors.primary};
        border-radius: 0;
        text-align: center;
        text-decoration: none;
        :hover {
            border: 1px solid ${colors.primary};
            background: ${colors.primary};
            color: #fff;
        }
         @media only screen and (max-width: 980px) {
            margin-bottom: 20px;
         }
    }
`;
const TituloPrincipal = styled.h1`
    font-family: Gotham-Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 40px;
    color: ${colors.primary};
    display: block;
    text-align: center;
    margin-top: 34px;
    @media only screen and (max-width: 480px) {
        font-size: 26px;
        line-height: 33px;
    }
`;
const TituloSecundario = styled.h2`
    font-family: Gotham-Book, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 44px;
    color: ${colors.black};
    display: block;
    text-align: center;
    margin-top: 5px;
    @media only screen and (max-width: 480px) {
        font-size: 22px;
        line-height: 25px;
    }
`;

export {
    ContainerBanner, ContainerHeight,
    TituloPrincipal, TituloSecundario,
};
